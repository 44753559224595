<template>
  <el-container>
    <el-header style="height:30px">
      <el-select v-model="company" placeholder="请选择分公司" v-if="currentUserCompany === '00'" @change="getCompany">
        <el-option v-for="item in companys_options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
      </el-select>
      <div class="searchdiv" style="float:right">
        <input class="searchinput" type="text" v-model="keyword" placeholder="编号 | 名称 | 签约人 | 项目 | 联系人 | 电话 | 负责人" @keyup.enter="getKeyword" />
        <button type="button" class="searchbutton" @click="getKeyword">搜索</button>
      </div>
    </el-header>
    <el-main>
      <common-table :tableData="tableData" :config="config" :usertype="usertype" @getSelectedRows="getSelectedRows" @changePage="getList"> </common-table>
    </el-main>
  </el-container>
</template>

<script>
// import CommonForm from '../../components/CommonForm'
import CommonTable from './heyueTable'
import utils from '@/assets/js/utils'

// import Cookie from 'js-cookie'

export default {
  components: {
    // CommonForm,
    CommonTable
  },
  data() {
    return {
      isShow: false,
      operateType: 'add',
      tableData: [],
      usertype: '',
      user_realname: '',
      currentUserCompany: utils.getUserCookie().company.code,

      company: utils.getCompanyCookie(),
      companys_options: [],
      keyword: '',

      config: {
        page: 1,
        total: 30,
        loading: false
      },

      searchFrom: {
        keyword: ''
      },
      formLabel: [
        {
          model: 'keyword',
          label: ''
        }
      ]
    }
  },
  watch: {
    'config.page': function() {
      utils.setPageCookie(this.config.page)
    }
  },

  methods: {
    //获取分公司列表
    getCompanyList() {
      this.$axios.get('/getCompanyList/').then(res => {
        console.log('companys', res.data)
        res.data.forEach(element => {
          let company = {}
          company.label = element.name
          company.value = element.code
          this.companys_options.push(company)
        })
        console.log('companys_options', this.companys_options)
      })
    },

    // 获取合同列表
    getList() {
      //获取当前用户信息：姓名，用户类型
      let user = utils.getUserCookie()
      this.usertype = user.user_type
      this.user_realname = user.name
      //判断是否是从详情页返回的
      let from = this.$route.query.from
      if (from === 'detail') {
        this.keyword = utils.getKeywordCookie()
        this.company = utils.getCompanyCookie()
        this.config.page = utils.getPageCookie()
        // alert(this.config.page)
        this.$route.query.from = ''
        console.log('this.keyword', this.keyword)
        console.log('this.company', this.company)
      }
      console.log('user:', user)
      console.log('usertype:', this.usertype)
      console.log('user_realname:', this.user_realname)
      console.log('company:', this.company)
      this.config.loading = true
      const api = '/getContractsforCailiaoTZD/'
      this.$axios
        .get(api, {
          params: {
            page: this.config.page,
            company: this.company, //公司编号code
            keyword: this.keyword,
            is_jiebanhetong: 0 //非借板合同
          }
        })
        .then(res => {
          console.log('res.data', res.data)

          this.config.total = res.data.totalcount
          console.log('total:', this.config.total)
          let data = res.data.data
          //排序
          data.sort(function(a, b) {
            //按照添加时间倒序
            // console.log(a.added_person_time)
            // console.log(b.added_person_time)
            let a_date = utils.stringToDate(a.added_person_time)
            let b_date = utils.stringToDate(b.added_person_time)
            return a_date < b_date ? 1 : -1
          })
          this.tableData = data.map(item => {
            item.financial_confirm_label = item.financial_confirm === true ? item.financial_confirm_person + '\n' + item.financial_confirm_time : '未确认'
            item.legal_audit_and_time = item.legal_audit + '\n' + item.legal_audit_time
            item.contract = item.contract_id + '\n' + item.contract_name
            item.signing = item.contractor + '\n' + item.signing_date
            item.second_party_project = item.second_party + '\n' + item.project
            item.financial_audit_and_time = item.financial_audit + '\n' + item.financial_audit_time
            item.added = item.added_person + '\n' + item.added_person_time
            return item
          })

          this.config.loading = false
        })
        .catch(function(error) {
          console.log(error)
        })
    },
    //获取选择的分公司
    getCompany() {
      utils.setCompanyCookie(this.company)
      console.log('company_cookie', this.company)
      this.getList()
    },
    //获取搜索关键词
    getKeyword() {
      // alert(this.keyword)
      utils.setKeywordCookie(this.keyword)
      this.config.page = 1
      console.log('keyword_cookie', this.keyword)
      this.getList()
    },

    getSelectedRows(val) {
      console.log('Selected rows:', val)
    }
  },
  created() {
    this.getCompanyList()
    this.getList()
  }
}
</script>
<style scoped>
.searchdiv {
  height: 30px;
  line-height: 30px;
}
.searchinput {
  width: 350px;
  height: 30px;
  text-align: center;
}
.searchbutton {
  width: 80px;
  height: 30px;
  background-color: #409eff;
}
.search-header {
  border: 1 solid;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
</style>
